<template>
<div>
  <b-row class="match-height">
    <b-col
      lg="12"
      md="12"
    >
   
    <b-card
      title="Edit Shift">
      <b-alert
        v-model="showDismissibleAlert"
        variant="danger"
        dismissible
        class="mb-1"
      >
        <div class="alert-body">
          {{error_message}}
        </div>
      </b-alert>
      
      <b-form @submit="formSubmit">
        
        <b-row>
          
          <b-col md="12">

            <b-row>
              <b-col md="12" >
                
                <b-form-group
                  label="Job Project Site"
                  class="required"

                >
                  <b-form-select v-model="form.site">

                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    
                    <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
                    
                    
                  </b-form-select>
                    
                </b-form-group>
              </b-col>

            </b-row>

            <b-row>
              <b-col md="12">
                <b-form-group
                  label="Shift"
                  class="required"
                >
                  <b-form-input
                    placeholder="2 to 50 characters"
                    v-model="form.shift"
                  />
                </b-form-group>
              </b-col>

              
            </b-row>

            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Start Time"
                  class="required"
                >
                    <!-- <b-form-timepicker v-model='form.startTime' locale='de' :show-seconds='showSecond' :hour12='hour12' hourCycle="h23"/> -->
                    <vue-timepicker v-model="form.startTime" format="HH:mm"></vue-timepicker>

                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="End Time"
                  class="required"
                >
                    <!-- <b-form-timepicker v-model='form.endTime' locale='de' :show-seconds='showSecond' :hour12='hour12' hourCycle="h23"/> -->
                    <vue-timepicker v-model="form.endTime" format="HH:mm"></vue-timepicker>

                </b-form-group>
              </b-col>

              
            </b-row>

            
            
            
            <b-row>
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="warning"
                  class="mr-1"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="$router.go(-1)"
                >
                  Cancel
                </b-button>
              </b-col>
          </b-row>


          </b-col>
        </b-row>

      </b-form>
    </b-card>
    </b-col>
  </b-row>

  
</div>
</template>

<script>

import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'


export default {
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BInputGroupPrepend,
    BInputGroup,
    BMedia,
    BAvatar,
    BTable,
    BModal,
    VueCropper,
    BFormRadio,
    BFormTimepicker,
    VueTimepicker
  },
  directives: {
    Ripple,
  },
  data() {
    return {

      
      error_message:null,
      showDismissibleAlert:false,

      form :{
        site : '',
        shift:'',
        startTime : {
          HH: '',
          mm: ''
        },
        endTime:{
          HH: '',
          mm: ''
        },
        id:'',
      },
      sites:[],
      /*showSecond : false,
      hour12: false,
      h23:'h23',*/
      
    }
  },
  methods : {
    formSubmit(e){
      e.preventDefault();

      return this.$store.dispatch(POST_API, {
           data:{
             items:this.form
           },
           api: '/api/update-shift'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Record Updated Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    this.$router.go(-1);
                });
                
            }
        });
      
    },

    allSites(){
      return this.$store.dispatch(POST_API, {
           data:{
                
                role:this.$store.getters.currentUser.role,
                om_sites:this.$store.getters.currentUser.om_sites,
           },
           api: '/api/all-sites'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.sites = data;
                return this.sites;
            }
        });
    },
    shiftDetail(){
      return this.$store.dispatch(POST_API, {
           data:{
             id:this.$route.params.id
           },
           api: '/api/shift-detail'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data = this.$store.getters.getResults.data;
                
                this.form.site = data.site;
                this.form.shift = data.shift;
                
                var start = data.start_time.split(':');
                this.form.startTime.HH = start[0];
                this.form.startTime.mm = start[1];

                var end = data.end_time.split(':');

                this.form.endTime.HH = end[0];
                this.form.endTime.mm = end[1];

                this.form.id = data._id;
                return this.form;
            }
        });
    }
    

  },
  mounted(){
    this.allSites();
    this.shiftDetail();
  }
}
</script>
